import React, { useEffect, useState } from "react";
import BackPaperImage from "../assets/layout/backgrounds/big_board.png";
// import { useAchievements } from "../components/AchievementsContext";
import { useUser } from "../components/contexts/UserContext";

const Achievements = () => {
  const { achievements } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative h-screen w-screen">
      <div className="flex flex-col w-full h-full justify-center items-center">
        <img
          src={BackPaperImage}
          className="absolute w-[310px] left-1/2 transform -translate-x-1/2"
        />
        <span className=" text-3xl z-10 mt-10">
          {achievements.length > 0 ? "Achievements" : "???"}
        </span>
        <ul className="flex flex-col h-full mt-6 w-[310px] max-h-[360px] overflow-y-scroll">
          {achievements.map((achievement, index) => (
            <li key={index} className="relative h-fit ">
              <div className="relative flex flex-row items-center py-2 px-6">
                <div className="w-12 h-8 border-black border-[3px] border-solid rounded-md">
                  <img
                    src={`data:image/png;base64,${achievement.img}`}
                    className="w-full h-full"
                  />
                </div>
                <div className="pr-2 pl-4 font-bold text-xl">
                  <span>{achievement.desc}</span>
                </div>
              </div>
              {index !== achievements.length - 1 && (
                <hr
                  width="84%"
                  color="#313229"
                  className="h-[3px] border-t-0 rounded-xl mx-6"
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Achievements;
