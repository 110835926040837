import React, { useContext, useState, useEffect } from "react";
import CrossIcon from "../../assets/layout/icons/cross.svg";
import BackgroundMusic from "../BackgroundMusic";
import CloseButton from "../CloseButton";
import { HeaderContext } from "./HeaderProvider";
import HeaderDays from "../HeaderDays";
import toast from "react-hot-toast";
import { id as userId } from "../../utils/TelegramUserData";
import { getToastOption } from "../../utils/Toast";
import { api } from "../../api/interface";
import { useAchievements } from "../AchievementsContext";

export const Header = () => {
  const { isShowCloseBtn } = useContext(HeaderContext);
  const { setAchievements } = useAchievements();
  const [userData, setUserData] = useState({});

  // useEffect(() => {
  //   api.betaGame
  //     .getUserDataMainScreen(userId)
  //     .then(({ data }) => {
  //       setUserData({
  //         userId: data["user_id"],
  //         score: data["score"],
  //         limit: data["daily_limit"],
  //         streak: data["daily_strike"],
  //       });
  //       setAchievements(data.achievements);
  //     })
  //     .catch((error) => {
  //       toast.error(
  //         "Sorry, seems like you are offline.",
  //         getToastOption(CrossIcon)
  //       );
  //       setUserData(false);
  //     });
  // }, [isShowCloseBtn]);

  return (
    <div className="relative w-full">
      {isShowCloseBtn ? (
        <CloseButton />
      ) : (
        <>
          <BackgroundMusic />
          {/* <HeaderDays streak={userData?.streak} limit={userData?.limit} /> */}
        </>
      )}
    </div>
  );
};
