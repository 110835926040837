import tape from "../assets/layout/backgrounds/tape.png";
import build from "../assets/layout/backgrounds/maintance.png";

function Building() {
  return (
    <div className="relative w-screen">
      <img className="relative" src={tape} alt="" />
      <img
        src={build}
        className="w-full pl-8 pr-8 text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </div>
  );
}

export default Building;
