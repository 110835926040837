import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { isMobileDevice } from "./utils";
import Leaderboard from "./pages/Leaderboard";
import Achievements from "./pages/Achievements";
import Wallet from "./pages/Wallet";
import Invite from "./pages/Invite";
//import Durov from "./pages/Durov";
import Game from "./pages/Game";
import Settings from "./pages/Settings";
import { MusicProvider } from "./components/MusicContext";
import { Header, HeaderProvider } from "./components/Header";
import OnlyMobile from "./pages/OnlyMobile";
import Characters from "./pages/Characters";
import MainMenu from "./pages/MainMenu";
import { AchievementsProvider } from "./components/AchievementsContext";
import { SFXProvider } from "./components/contexts/SFXContext";
import { UserProvider } from "./components/contexts/UserContext";
import { id } from "./utils/TelegramUserData";
import Building from "./pages/Building";
function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  // if (!isMobile || id === undefined) {
  //   return <OnlyMobile />;
  // }

  return (
    <AchievementsProvider>
      <UserProvider>
        <MusicProvider>
          <SFXProvider>
            <HeaderProvider>
              <Router>
                <Header />
                <Toaster
                  toastOptions={{
                    className: "toast",
                    position: "center top",
                    icon: "✔",
                    style: {
                      padding: 0,
                      color: "#000",
                      width: "100dvw",
                    },
                  }}
                />
                <Routes>
                  <Route path="/" element={<MainMenu />} />
                  {/* <Route path="/game" element={<Game />} /> */}
                  <Route path="/game" element={<Building />} />
                  <Route path="/achievements" element={<Achievements />} />
                  <Route path="/leaderboard" element={<Leaderboard />} />
                  <Route path="/invite" element={<Invite />} />
                  <Route path="/wallet" element={<Wallet />} />
                  <Route path="/characters" element={<Characters />} />
                  {/*<Route path="/durov" element={<Durov />}  /> */}
                  <Route path="/settings" element={<Settings />} />
                </Routes>
              </Router>
            </HeaderProvider>
          </SFXProvider>
        </MusicProvider>
      </UserProvider>
    </AchievementsProvider>
  );
}

export default App;
