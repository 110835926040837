import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import backgroundMusic from "../assets/audio/menuAudio/MenuMusic.wav";

const MusicContext = createContext();

export const useMusic = () => {
  return useContext(MusicContext);
};

export const MusicProvider = ({ children }) => {
  const [musicVolume, setMusicVolume] = useState(() => {
    const savedVolume = localStorage.getItem("musicVolume");
    return savedVolume !== null ? parseFloat(savedVolume) : 0.2;
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const audioContextRef = useRef(null);
  const audioBufferRef = useRef(null);
  const sourceRef = useRef(null);
  const gainNodeRef = useRef(null);

  const initAudioContext = async () => {
    if (!audioContextRef.current) {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      audioContextRef.current = audioContext;

      const gainNode = audioContext.createGain();
      gainNode.gain.value = musicVolume;
      gainNode.connect(audioContext.destination);
      gainNodeRef.current = gainNode;

      const response = await fetch(backgroundMusic);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      audioBufferRef.current = audioBuffer;

      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (isLoaded && isPlaying) {
      playAudio();
    } else if (sourceRef.current) {
      stopAudio();
    }
  }, [isLoaded, isPlaying]);

  const playAudio = () => {
    if (!audioContextRef.current || !audioBufferRef.current) return;

    if (sourceRef.current) {
      sourceRef.current.stop();
    }

    const audioContext = audioContextRef.current;
    const source = audioContext.createBufferSource();
    source.buffer = audioBufferRef.current;
    source.loop = true;
    source.connect(gainNodeRef.current);
    source.start(0);
    sourceRef.current = source;
  };

  const stopAudio = () => {
    if (sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current = null;
    }
  };

  const toggleMusic = () => {
    if (!isLoaded) {
      initAudioContext();
    }
    setIsPlaying((prev) => !prev);
  };

  const setVolume = (value) => {
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = value;
    }
    setMusicVolume(value);
    localStorage.setItem("musicVolume", value);
  };

  return (
    <MusicContext.Provider
      value={{ musicVolume, setVolume, toggleMusic, isPlaying }}
    >
      {children}
    </MusicContext.Provider>
  );
};
