import axios from "axios";

//MARK: НЕ ЗАБУДЬ ПОМЕНЯТЬ И CORS!!!!
const baseUrl = "https://dev.bigballsbirds.fun/api";

function get(path) {
  return axios
    .get(`${baseUrl}${path}`)
    .then((res) => res.data)
    .then((res) => {
      if (res.status) {
        return res;
      }
      console.log(res.message);
      throw new Error(res.message);
    });
}

function post(path, data) {
  return axios
    .post(`${baseUrl}${path}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: false,
      validateStatus: () => true
    })
    .then((res) => res.data);
}

function patch(path, data) {
  return axios
    .patch(`${baseUrl}${path}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data)
    .then((res) => {
      if (res.status) {
        return res;
      }
      console.log(res.message);
      throw new Error(res.message);
    });
}

function del(path) {
  return axios
    .delete(`${baseUrl}${path}`, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .then((res) => {
      if (res.status) {
        return res;
      }
      console.log(res.message);
      throw new Error(res.message);
    });
}

const api = {
  betaGame: {
    getLeaderboard: (userId) => get(`/leaderboard/${userId}`),

    getUserReferrals: (userId) =>
      axios
        .post(`https://bigballsbirds.fun/api/betagame/referrals/${userId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data),
  },
  balls: {},
};

export { api, post };
