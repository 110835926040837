import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://17ab4e0db1a817b31b70f420bfa5abc2@o4507566075871232.ingest.de.sentry.io/4507566077444176",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.replayCanvasIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <TonConnectUIProvider
    manifestUrl="https://ton-connect.github.io/demo-dapp-with-react-ui/tonconnect-manifest.json"
    uiPreferences={{ theme: THEME.DARK }}
    walletsListConfiguration={{
      includeWallets: [
        {
          appName: "safepalwallet",
          name: "SafePal",
          imageUrl: "https://s.pvcliping.com/web/public_image/SafePal_x288.png",
          tondns: "",
          aboutUrl: "https://www.safepal.com",
          universalLink: "https://link.safepal.io/ton-connect",
          jsBridgeKey: "safepalwallet",
          bridgeUrl: "https://ton-bridge.safepal.com/tonbridge/v1/bridge",
          platforms: ["ios", "android", "chrome", "firefox"],
        },
        {
          appName: "tonwallet",
          name: "TON Wallet",
          imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
          aboutUrl:
            "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
          universalLink: "https://wallet.ton.org/ton-connect",
          jsBridgeKey: "tonwallet",
          bridgeUrl: "https://bridge.tonapi.io/bridge",
          platforms: ["chrome", "android"],
        },
      ],
    }}
    actionsConfiguration={{
      twaReturnUrl: "https://t.me/DemoDappWithTonConnectBot/demo",
    }}
  >
    <App />
  </TonConnectUIProvider>
);
