import React, { useContext } from "react";
import { useSFX } from "../components/contexts/SFXContext";
import BackSound from "../assets/audio/menuAudio/back.wav";
import toast from "react-hot-toast";

const Slider = ({ value, onChange, label, onMouseUp, onTouchEnd }) => {
  const percentage = Math.round(value * 100);
  const { playSound } = useSFX();

  const handleChange = (e) => {
    const newValue = e.target.value / 100;
    onChange({ target: { value: newValue } });
  };

  const handleMouseUp = (e) => {
    onMouseUp(e);
    playSound(BackSound);
    toast.success(<p className="text-xl">Settings applied and saved!</p>);
  };

  return (
    <div className="slider-container w-full mb-2 mt-6">
      <label className="block text-xl mb-2">{label}</label>
      <div className="slider-wrapper">
        <div className="slider-progress" style={{ width: `${percentage}%` }}></div>
        <input
          type="range"
          min="0"
          max="100"
          value={percentage}
          onChange={handleChange}
          onMouseUp={handleMouseUp}
          onTouchEnd={handleMouseUp}
          className="slider"
        />
      </div>
      <div className="absolute top-0 right-0 text-xl">{percentage}%</div>
    </div>
  );
};

export default Slider;
