import BackPaperImage from "../assets/layout/leaderboard/leaderboard.png";
import React, { useEffect, useState } from "react";
import { formatName, formatScore, formatPosition } from "../utils/Formaters";
import { id } from "../utils/TelegramUserData";
import { api } from "../api/interface";
import toast from "react-hot-toast";
import { getToastOption } from "../utils/Toast";
import { username } from "../utils/TelegramUserData";
import crossImg from "../assets/layout/icons/cross.svg";

const Leaderboard = () => {
  const [topUsers, setTopUsers] = useState([
    { username: "???", highestScore: 0 },
    { username: "???", highestScore: 0 },
    { username: "???", highestScore: 0 },
    { username: "???", highestScore: 0 },
    { username: "???", highestScore: 0 },
  ]);

  const [user, setUser] = useState({
    userId: "You",
    highestScore: 0,
    place: 0,
  });

  useEffect(() => {
    api.betaGame
      .getLeaderboard(id)
      .then((res) => {
        let data = res.data;
        setTopUsers(data[0]);
        if (data[1] === null) {
          setUser({
            userId: `${username}`,
            highestScore: 0,
            place: ">10000",
          });
        }
        setUser({
          userId: "You",
          highestScore: data[1].highestScore,
          place: data[1].place,
        });
        console.log(data);
      })
      .catch((error) => {
        toast.error("Something went wrong.", getToastOption(crossImg, 5000));
        console.log(error);
      });
  }, []);

  return (
    <div className="relative h-screen w-screen">
      <div className="absolute flex flex-col left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img src={BackPaperImage} className="relative  mt-14 w-[310px]" />

        <ul className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-[calc(50%-85px)] w-full max-w-[310px]">
          {topUsers.map((member, index) => (
            <li
              key={index}
              className="relative flex flex-col justify-center items-center h-fit pt-4 px-8"
            >
              <div className="data top-0 w-full flex flex-row pb-[12px]">
                <div className="ml-2 mr-4 text-2xl">{index + 1}</div>
                <div className="flex flex-row justify-between w-full">
                  <div className="pl-4 text-2xl border-black border-solid border-l-[3px]">
                    {member.username}
                  </div>
                  <div className="text-2xl">{member.highestScore}</div>
                </div>
              </div>
              {index !== 4 && (
                <hr
                  width="100%"
                  color="#313229"
                  className="h-[3px] border-t-0 rounded-xl"
                />
              )}
            </li>
          ))}
          <hr
            width="96%"
            color="#313229"
            className="h-[3px] border-t-0 m-auto"
          />

          {user && (
            <li className="relative flex flex-col justify-center items-center h-fit py-4 px-8">
              <div className="data top-0 w-full flex flex-row">
                <div className="ml-2 mr-4 text-2xl">
                  {formatPosition(String(user.place))}
                </div>
                <div className="flex flex-row justify-between w-full">
                  <div
                    className="pl-4 text-2xl border-black border-solid"
                    style={{ borderLeftWidth: "3px" }}
                  >
                    {formatName(user.userId)}
                  </div>
                  <div className="text-2xl">
                    {formatScore(user.highestScore || 0)}
                  </div>
                </div>
              </div>
            </li>
          )}
          <hr
            width="96%"
            color="#313229"
            className="h-[3px] border-t-0 m-auto"
          />
          <div className="flex w-full justify-between px-10 pt-2 text-2xl">
            <span>Your Balance: </span>
            <span>{formatScore(user.balance || 0)}</span>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Leaderboard;
