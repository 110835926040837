import React, { createContext, useContext, useState } from 'react';

const AchievementsContext = createContext();

export const AchievementsProvider = ({ children }) => {
    const [achievements, setAchievements] = useState([]);

    return (
        <AchievementsContext.Provider value={{ achievements, setAchievements }}>
            {children}
        </AchievementsContext.Provider>
    );
};

export const useAchievements = () => useContext(AchievementsContext);
