import React, { useEffect, useRef, useContext } from "react";
import gear from "../assets/layout/icons/settings.png";
import { useNavigate } from "react-router-dom";
import { useSFX } from "./contexts/SFXContext";
import SelectSound from "../assets/audio/menuAudio/select.wav";
const BackgroundMusic = () => {
  const navigate = useNavigate();
  const { playSound } = useSFX();

  const playSelectSound = () => {
    playSound(SelectSound);
  };

  const goToSettings = () => {
    navigate("/settings");
    playSelectSound();
  };

  return (
    <div className="absolute left-10 size-12 z-20 mt-5">
      <button className="absolute w-12 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" onClick={goToSettings}>
        <img src={gear} className="size-12" />
      </button>
    </div>
  );
};

export default BackgroundMusic;
