import React, { useRef } from "react";
import BackPaperImage from "../assets/layout/backgrounds/small_board.png";
import Slider from "../components/Slider";
import { useMusic } from "../components/MusicContext";
import { useSFX } from "../components/contexts/SFXContext";
import enable from "../assets/layout/buttons/enable.png";

function Settings() {
  const { musicVolume, setVolume, toggleMusic, isPlaying } = useMusic();
  const { sfxVolume, setSFXVolume } = useSFX();
  const musicSliderRef = useRef(null);
  const sfxSliderRef = useRef(null);

  const handleVolumeChange = (e) => {
    setVolume(e.target.value);
  };

  const handleSFXVolumeChange = (e) => {
    setSFXVolume(e.target.value);
  };

  const handleMouseUp = (e) => {
    if (musicSliderRef.current) {
      const musicVolume = musicSliderRef.current.value / 100;
      localStorage.setItem("musicVolume", musicVolume);
    }
    if (sfxSliderRef.current) {
      const sfxVolume = sfxSliderRef.current.value / 100;
      localStorage.setItem("sfxVolume", sfxVolume);
    }
  };

  return (
    <div className="relative w-dvw h-dvh w-screen h-screen">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="relative">
          <img src={BackPaperImage} className="relative w-[350px]" />
          <div className="absolute w-full h-full p-8 top-0 flex flex-col items-center">
            <div className="text-4xl">Settings</div>

            <Slider
              label="SFX effects"
              value={sfxVolume}
              onChange={handleSFXVolumeChange}
              onMouseUp={handleMouseUp}
              onTouchEnd={handleMouseUp}
              ref={sfxSliderRef}
            />
            <Slider
              label="music volume"
              value={musicVolume}
              onChange={handleVolumeChange}
              onMouseUp={handleMouseUp}
              onTouchEnd={handleMouseUp}
              ref={musicSliderRef}
            />
            <div className="flex w-full justify-center items-center mt-6">
              <button className="" onClick={toggleMusic}>
                <img className="w-56" src={enable} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
