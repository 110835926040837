import React, { createContext, useContext, useState, useEffect, useRef } from "react";

const SFXContext = createContext();

export const useSFX = () => {
  return useContext(SFXContext);
};

export const SFXProvider = ({ children }) => {
  const [sfxVolume, setSFXVolume] = useState(() => {
    const savedVolume = localStorage.getItem("sfxVolume");
    return savedVolume !== null ? parseFloat(savedVolume) : 0.2;
  });
  const cache = useRef({})

  const playSound = (sound, name) => {
    let audio;
    if (name) {
      if (!cache[name]) {
        audio = new Audio(sound);
        cache[name] = audio
      } else {
        audio = cache[name]
      }
    } else {
      audio = new Audio(sound);
    }

    audio.volume = sfxVolume;
    audio.play();
  };

  const handleSFXVolumeChange = (value) => {
    setSFXVolume(value);
    localStorage.setItem("sfxVolume", value);
  };

  return (
    <SFXContext.Provider
      value={{ sfxVolume, setSFXVolume: handleSFXVolumeChange, playSound }}
    >
      {children}
    </SFXContext.Provider>
  );
};
