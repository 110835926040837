import React, { useContext, useEffect, useRef } from "react";
import HeaderImage from "../assets/main-title-beta.png";
import PlayImage from "../assets/layout/buttons/play.png";
import AchievementImage from "../assets/layout/icons/Achievements.png";
import LeaderboardImage from "../assets/layout/icons/Leaderboard.png";
import InviteImage from "../assets/layout/icons/Invites.png";
import WalletImage from "../assets/layout/icons/Wallet.png";
import CharacterImage from "../assets/layout/icons/hero.png";
import SelectSound from "../assets/audio/menuAudio/select.wav";
import { useNavigate } from "react-router-dom";
import { HeaderContext } from "../components/Header/HeaderProvider";
import { useSFX } from "../components/contexts/SFXContext";
import { id } from "../utils/TelegramUserData";
import { useUser } from "../components/contexts/UserContext";
import { post } from "../api/interface";
import { useState } from "react";
import image from "../assets/OnlyMobile/not_an_easter_egg.gif";
import sound from "../assets/OnlyMobile/what.mp3";

// import Durov from "./Durov";

const MainMenu = () => {
  const [counter, setCounter] = useState(0);

  const { playSound } = useSFX();
  const navigate = useNavigate();

  const { setIsShowCloseBtn } = useContext(HeaderContext);

  useEffect(() => {
    setIsShowCloseBtn(false);

    return () => {
      setIsShowCloseBtn(true);
    };
  }, []);

  const playSelectSound = () => {
    playSound(SelectSound);
  };

  const goToGame = () => {
    playSelectSound();
    navigate("/game");
  };

  const goToLeaderboard = () => {
    playSelectSound();
    navigate("/leaderboard");
  };

  const goToAchievement = () => {
    playSelectSound();
    navigate("/achievements");
  };

  const goToInvite = () => {
    playSelectSound();
    navigate("/invite");
  };

  const goToWallet = () => {
    playSelectSound();
    navigate("/wallet");
  };

  const goToCharacters = () => {
    playSelectSound();
    navigate("/characters");
  };

  useEffect(() => {
    if (counter == 100) {
      playSound(sound);
    }
  }, [counter]);

  return (
    <div className="relative w-dvw h-dvh w-screen h-screen">
      <div className="absolute w-dvw h-dvh w-screen h-screen top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="game-interface w-dvw h-dvh w-screen h-screen">
          {/* <Durov /> */}
          <div className="header-container mt-16">
            <img className="w-4/5 " src={HeaderImage} />
          </div>
          <div className="absolute z-30 flex-1 flex justify-center w-screen top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {counter == 100 ? (
              <div>
                <img
                  className="w-screen h-screen absolute z-50 -top-[calc(100%_+_28px)] left-0"
                  src={image}
                />
                {setTimeout(() => {
                  setCounter(0);
                }, 4000)}
              </div>
            ) : (
              ""
            )}
            <div
              onClick={() => {
                setCounter((prev) => prev + 1);
              }}
              id="bird-animation"
              className="aspect-square w-4/6 max-w-[550px]"
            ></div>
          </div>
          <div className="game-menu-container my-8">
            <div className="first-layer">
              <button className="menu-button" onClick={goToGame}>
                <img src={PlayImage} />
              </button>
              <button className="menu-button" onClick={goToAchievement}>
                <img src={AchievementImage} />
              </button>
            </div>
            <div className="second-layer">
              <button className="menu-button" onClick={goToLeaderboard}>
                <img src={LeaderboardImage} className="w-24" />
              </button>
              <button className="menu-button" onClick={goToInvite}>
                <img src={InviteImage} className="w-24" />
              </button>
              {/* <button className="menu-button" onClick={goToWallet}>
                                <img src={WalletImage} className="w-16"/>
                            </button> */}
              <button className="menu-button" onClick={goToCharacters}>
                <img src={CharacterImage} className="w-24" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
