import React, { useRef, useState } from "react";
import BackPaperImage from "../assets/layout/backgrounds/big_board.png";
import InviteFriendsButton from "../assets/layout/buttons/button_empty.png";
import Bird from "../assets/layout/characters/default-bird.png";
import SecretBird from "../assets/layout/characters/secret-bird.png";
import ArrowLeft from "../assets/layout/characters/arrow-l.svg";
import ArrowRight from "../assets/layout/characters/arrow-r.svg";
import SelectSound from "../assets/audio/menuAudio/select.wav";
import { useSFX } from "../components/contexts/SFXContext";

const BIRD_SIZE = 220;
const TEXT_WIDTH = 286;

const initialBirds = [
  { img: Bird, name: "Big Balls Bird", available: true },
  { img: SecretBird, name: "Coming Soon", available: false },
];

const Characters = () => {
  const [birds, setBirds] = useState(initialBirds);
  const [birdIndex, setBirdIndex] = useState(0);
  const imgSliderRef = useRef();
  const nameSliderRef = useRef();
  const { playSound } = useSFX();

  const handleApply = () => {
    playSound(SelectSound);
  };

  const handleToLeft = () => {
    playSound(SelectSound);
    if (birdIndex > 0) {
      imgSliderRef.current.scrollTo({
        left: BIRD_SIZE * (birdIndex - 1),
        behavior: "smooth",
      });
      nameSliderRef.current.scrollTo({
        left: TEXT_WIDTH * (birdIndex - 1),
        behavior: "smooth",
      });
      setBirdIndex(birdIndex - 1);
    }
  };

  const handleToRight = () => {
    playSound(SelectSound);
    if (birdIndex < birds.length - 1) {
      imgSliderRef.current.scrollTo({
        left: BIRD_SIZE * (birdIndex + 1),
        behavior: "smooth",
      });
      nameSliderRef.current.scrollTo({
        left: TEXT_WIDTH * (birdIndex + 1),
        behavior: "smooth",
      });
      setBirdIndex(birdIndex + 1);
    }
  };

  return (
    <div className="relative w-dvw h-dvh w-screen h-screen">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="relative">
          <img src={BackPaperImage} className="relative w-[350px]" />
          <div className="absolute w-full h-full p-8 top-0 flex flex-col items-center">
            <div className="text-3xl">Character</div>
            <div className="flex-1 flex items-center justify-center">
              <button onClick={handleToLeft}>
                <img
                  src={ArrowLeft}
                  className={`h-16 ${birdIndex === 0 ? "opacity-20" : ""}`}
                />
              </button>
              <div
                ref={imgSliderRef}
                style={{ width: `${BIRD_SIZE}px` }}
                className={`flex flex-row overflow-x-hidden scroll-smooth`}
              >
                {birds.map((bird, index) => (
                  <img
                    key={index}
                    src={bird.img}
                    style={{ width: `${BIRD_SIZE}px` }}
                  />
                ))}
              </div>
              <button onClick={handleToRight}>
                <img
                  src={ArrowRight}
                  className={`h-16 ${
                    birdIndex === birds.length - 1 ? "opacity-20" : ""
                  }`}
                />
              </button>
            </div>
            <div className="w-full">
              <div className="flex flex-col items-center mb-4">
                <hr
                  width="100%"
                  color="#313229"
                  className="h-[3px] border-t-0 rounded-xl"
                />
                <div
                  ref={nameSliderRef}
                  className="py-2 w-full flex flex-row overflow-x-hidden scroll-smooth"
                >
                  {birds.map((bird, index) => (
                    <div
                      key={index}
                      className="text-xl text-center text-nowrap"
                    >
                      <div style={{ width: `${TEXT_WIDTH}px` }}>
                        {bird.name}
                      </div>
                    </div>
                  ))}
                </div>
                <hr
                  width="100%"
                  color="#313229"
                  className="h-[3px] border-t-0 rounded-xl"
                />
              </div>
              <button
                onClick={handleApply}
                className={
                  "relative w-full h-10 " +
                  (birds[birdIndex].available ? "" : "grayscale")
                }
              >
                <div className="absolute w-full flex justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <img src={InviteFriendsButton} className="w-5/6 h-auto" />
                </div>
                <div className="absolute text-2xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  {birds[birdIndex].available ? "Apply" : "Soon"}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Characters;
