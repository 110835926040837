import React, { createContext, useContext, useEffect, useState } from "react";
import { post } from "../../api/interface";
const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [achievements, setAchievements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const data = JSON.stringify({ initData: window.Telegram.WebApp.initData });

    const fetchUser = async () => {
      try {
        const response = await post("/user", data);
        const userData = response.data;
        const achievementsData = response.status ? response.data.achievements : [];
        setUser(userData);
        setAchievements(achievementsData);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setError(err);
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  return (
    <UserContext.Provider
      value={{ user, achievements, setAchievements, loading, error }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
