import CloseButtonImage from "../assets/layout/buttons/close-button.png";
import BackSound from "../assets/audio/menuAudio/back.wav";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSFX } from "./contexts/SFXContext";
const CloseButton = () => {
  const navigate = useNavigate();
  const { playSound } = useSFX();
  const playBackSound = () => {
    playSound(BackSound);
  };

  const goToMain = () => {
    navigate("/");
    playBackSound();
  };

  return (
    <>
      <button className="close-button" onClick={goToMain}>
        <img src={CloseButtonImage} className="w-10" />
      </button>
    </>
  );
};

export default CloseButton;
